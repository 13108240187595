/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useEditRequest, useRequestActions } from 'src/components/hooks';

import {
  TableRow,
  TableCell,
  Box,
  Popover,
  MenuItem,
  Select,
  Button,
  InputLabel,
  IconButton,
  FormControl,
  Link,
  OutlinedInput,
  Label,
  Typography,
} from 'src/components/ui';

import { SettingsIcon, UserIcon } from 'src/components/assets/icons';
import { fDate, formatDate } from 'src/components/utils/format-time';

export const RequestsRow = ({ row }) => {
  const { setRequestStatus } = useRequestActions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionValue, setActionValue] = useState('');

  const { setIsDrawerOpen, isDrawerOpen } = useEditRequest();

  const handleAction = () => {
    setRequestStatus(row.id, actionValue);
    setAnchorEl(false);
  };

  const handleRow = (e) => {
    if (!isDrawerOpen && !anchorEl) {
      setIsDrawerOpen(true, row.id);
    }
  };

  console.log(row);

  return (
    <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleRow}>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.account?.nexudus_id}</TableCell>

      <TableCell>
        <Link
          href={row.account?.profile_url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'flex',
            background: '#B4BBC1',
            borderRadius: '8px',
            color: 'white',
            fontWeight: 'bold',
            paddingInline: '0.7rem',
            paddingBottom: '0.2rem',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <UserIcon
            sx={{
              color: 'white',
              width: '1.2rem',
            }}
          />
          <Typography sx={{ marginTop: '0.1rem', fontSize: '0.9rem' }}>
            {row.account?.account_type === 1 ? 'Personal' : 'Company'}
          </Typography>
        </Link>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.item_amount}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>Additional</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatDate(row?.created_at)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Label
          sx={{ width: '90px' }}
          color={
            (row.status === 'DECLINED' && 'error') ||
            (row.status === 'APPROVED' && 'warning') ||
            (row.status === 'PURCHASED' && 'success') ||
            'default'
          }
        >
          {row?.status}
        </Label>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatDate(row?.last_seen)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.inventory.name}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.inventory.category?.title} - {row?.inventory?.name}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {formatDate(row?.purchase_date)}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', position: 'relative' }}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }}
        >
          <SettingsIcon sx={{ color: '#1224FF' }} />
        </IconButton>

        <Popover
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
          }}
          transformOrigin={{
            vertical: 'top',
          }}
        >
          <Box
            sx={{
              borderRadius: '14px',
              p: 2,
            }}
          >
            <FormControl sx={{ width: '200px' }}>
              <InputLabel id="name-label">Actions</InputLabel>
              <Select
                value={actionValue}
                onChange={(e) => {
                  setActionValue(e.target.value);
                }}
                fullWidth
                input={<OutlinedInput label="Actions" />}
              >
                <MenuItem value="approve">Approve</MenuItem>
                <MenuItem value="decline">Decline</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              marginLeft: '20px',
              marginBottom: '14px',
            }}
          >
            <Button
              style={{
                color: 'white',
                backgroundColor: '#00a66e',
                height: '30px',
                width: '90px',
              }}
              onClick={handleAction}
            >
              Apply
            </Button>
            <Button
              style={{
                color: 'white',
                backgroundColor: '#FF5530',
                height: '30px',
                width: '90px',
              }}
              onClick={() => setAnchorEl(null)}
            >
              Cancel
            </Button>
          </Box>
        </Popover>
      </TableCell>
    </TableRow>
  );
};

RequestsRow.propTypes = {
  row: PropTypes.object.isRequired,
};
