/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  useEditCategories,
  useEditContent,
  useEditInventory,
  useGetContent,
  useGetContentCategories,
} from 'src/components/hooks';

import { useFormik, FormikProvider, Form, ErrorMessage } from 'formik';
import { Grid } from '@mui/material';
import { UploadIcon } from 'src/components/assets/icons/Icons';

import {
  Select,
  MenuItem,
  Box,
  Label,
  Button,
  Scrollbar,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Drawer,
  FormControl,
  InputLabel,
  TextField,
} from 'src/components/ui';

export const ContentDrawer = ({ contentId }) => {
  const { data: contentData } = useGetContent();
  const { editContent, isDrawerOpen, setIsDrawerOpen } = useEditContent();

  const { editCategories } = useEditCategories();
  const [position, setPosition] = useState('');
  const [price_from, setPriceFrom] = useState('');

  const [imageLinks, setImageLinks] = useState([]);
  const [images, setImages] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      recommended_for: '',

      // geoName: '',
      // geoDescription: '',
      // geoRecommended_for: '',
    },
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async () => {
      try {
        const payload = {
          ...formik.values,
          images,
        };

        const result = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/categories/${contentId}/content`,
          {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        editCategories({ position, price_from }, contentId);
        setIsDrawerOpen(false);
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  });

  const handleOpenedDrawer = () => {
    setIsDrawerOpen(false);
    formik.resetForm();
    setPosition('');
    setImages([]);
  };

  // set
  useEffect(() => {
    if (!contentData) return;
    formik.setValues(contentData);
    setPosition(contentData.category?.position);
    setPriceFrom(contentData.category?.price_from);

    setImageLinks(contentData.images);
  }, [contentData]);

  // upload
  const handleImageUpload = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);

      filesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const newImage = {
            base64: reader.result, // Base64 string
            filename: file.name, // Filename
          };

          // Update images array with new images
          setImages((prevImages) => [...prevImages, newImage]);

          // Update image source (for preview or display)
          setImageLinks((prevSrc) => {
            const safePrevSrc = Array.isArray(prevSrc) ? prevSrc : [];
            return [...safePrevSrc, reader.result];
          });
        };
        reader.readAsDataURL(file); // Read file as base64
      });
    }
  }; 

  if (!isDrawerOpen) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleOpenedDrawer}
      PaperProps={{
        sx: {
          width: '30%',
          '@media (max-width: 600px)': {
            width: '70%',
          },
        },
      }}
    >
      <FormikProvider value={formik}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
          }}
          onSubmit={formik.handleSubmit}
        >
          {/*  */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '24px',
            }}
          >
            {/* CARD */}
            <Typography>Content</Typography>

            <Grid container spacing={3} sx={{ marginTop: '24px' }}>
              {/* First Column */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  <ErrorMessage name="name" component="div" />
                </FormControl>
              </Grid>

              {/* Second Column */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="geoName"
                    label="სახელი"
                    value={formik.values.geoName}
                    onChange={formik.handleChange}
                  />
                  <ErrorMessage name="geoName" component="div" />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="recommended_for"
                    label="Recommended For"
                    value={formik.values.recommended_for}
                    onChange={formik.handleChange}
                  />
                  <ErrorMessage name="recommended_for" component="div" />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="geoRecommended_for"
                    label="რეკომენდირებულია"
                    value={formik.values.geoRecommended_for}
                    onChange={formik.handleChange}
                  />
                  <ErrorMessage name="geoRecommended_for" component="div" />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    multiline
                    rows={3}
                  />
                  <ErrorMessage name="description" component="div" />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="geoDescription"
                    label="აღწერა"
                    value={formik.values.geoDescription}
                    onChange={formik.handleChange}
                    multiline
                    rows={3}
                  />
                  <ErrorMessage name="geoDescription" component="div" />
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControl fullWidth sx={{ marginTop: '24px', width: '48%' }}>
                <TextField
                  label="Position"
                  type="number"
                  value={position}
                  onChange={(e) => {
                    setPosition(Number(e.target.value));
                  }}
                />
              </FormControl>

              <FormControl fullWidth sx={{ marginTop: '24px', width: '48%' }}>
                <TextField
                  label="Price from"
                  type="price_from"
                  value={price_from}
                  onChange={(e) => {
                    setPriceFrom(Number(e.target.value));
                  }}
                />
              </FormControl>
            </Box>

            {/* Image Upload */}
            <Box sx={{ marginTop: '24px' }}>
              <input type="file" accept="image/*" multiple onChange={handleImageUpload} />
            </Box>

            <Box flex>
              {imageLinks?.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Uploaded ${index}`}
                  style={{
                    maxWidth: '100%',
                    borderRadius: '1rem',
                    marginTop: '2rem',
                    width: '148px',
                    height: '148px',
                    marginRight: '8px',
                  }}
                />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              marginTop: '48px',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                width: '100px',
                height: '50px',
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#3B25FF',
              }}
              variant="contained"
              type="submit"
              // disabled={!isValid}
            >
              Apply
            </Button>

            <Label
              sx={{
                position: 'relative',
                minWidth: '100px',
                height: '50px',
                marginLeft: '24px',
                cursor: 'pointer',
              }}
              onClick={handleOpenedDrawer}
            >
              Cancel
            </Label>
          </Box>
        </Form>
      </FormikProvider>
    </Drawer>
  );
};

ContentDrawer.propTypes = {
  contentId: PropTypes.string,
};
