import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  useGetInventories,
  useAddInventory,
  useDeleteInventory,
  useEditInventory,
  useGetToken,
} from 'src/components/hooks';
import { useNavigate } from 'react-router';
import { useSpinner } from 'src/components/hooks/useExtraStore';

import {
  Box,
  Container,
  TableNoData,
  useTable,
  Card,
  Scrollbar,
  CustomBreadcrumbs,
  Spinner,
} from 'src/components/ui';

import { InventoryHead, InventoryRow, InventoryPagination, InventoryDrawer } from './ui';

export const InventoryTable = () => {
  const { getInventories, data } = useGetInventories();
  const { isInventoryAdded } = useAddInventory();
  const { isInventoryDeleted } = useDeleteInventory();
  const { isInventoryEdited } = useEditInventory();
  const table = useTable();
  const [status, setStatus] = useState('Stock');
  const { setSpinner } = useSpinner();

  const handleSearch = (type, value) => {};

  useEffect(() => {
    setSpinner(true);
    getInventories(table.page, table.rowsPerPage);
  }, [
    table.page,
    table.rowsPerPage,
    isInventoryAdded,
    isInventoryDeleted,
    isInventoryEdited,
  ]);

  return (
    <Box>
      <Helmet>
        <title>Inventory List</title>
      </Helmet>
      <Container
        sx={{
          width: {
            xs: '100%',
            sm: 1200,
          },
        }}
      >
        <CustomBreadcrumbs
          heading="Inventory List"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          {status === 'Stock' && (
            <Scrollbar>
              <InventoryHead
                order={table.order}
                orderBy={table.orderBy}
                rowCount={data.items?.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                handleSearch={handleSearch}
              />
              {data.items?.map((row) => (
                <InventoryRow row={row} key={row.id} />
              ))}

              <TableNoData notFound={!data.items?.length} />
            </Scrollbar>
          )}

          <InventoryPagination
            count={data.meta?.totalItems}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            handleSearch={handleSearch}
          />
          <InventoryDrawer />
        </Card>
      </Container>
    </Box>
  );
};
