import { useState } from 'react';
import PropTypes from 'prop-types';

import { FilterListIcon } from 'src/components/assets/icons';

import {
  TableRow,
  TableCell,
  TableHead,
  Box,
  Button,
  MenuItem,
  InputLabel,
  Modal,
  Select,
  TextField,
  Checkbox,
  IconButton,
  SearchIcon,
  Popover,
} from 'src/components/ui';
import { useGetInventories } from 'src/components/hooks';

const columns = [
  { id: 1, label: 'Type', width: '150px', property: 'type' },
  { id: 2, label: 'Category', width: '150px', property: 'category' },
  { id: 3, label: 'Name', width: '150px', property: 'search' },
  { id: 4, label: 'Available Date', width: '200px' },
  { id: 5, label: 'Quantity', width: '150px' },
  { id: 6, label: 'Status', width: '150px', property: 'status' },
  { id: 7, label: 'Actions', width: '200px' },
];

export const InventoryHead = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchColumn, setSearchColumn] = useState('');
  const { getInventories } = useGetInventories();

  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');

  const handleSearch = (e) => {
    if (searchColumn === 'type') {
      setType(e);
    } else if (searchColumn === 'category') {
      setCategory(e);
    } else if (searchColumn === 'search') {
      setSearch(e);
    } else if (searchColumn === 'date') {
      setDate(e);
    } else if (searchColumn === 'status') {
      setStatus(e);
    }

    const searchObject = {
      type: searchColumn === 'type' ? e : type,
      // category: searchColumn === 'category' ? e : category,
      search: searchColumn === 'search' ? e : search,
      // date: searchColumn === 'date' ? e : date,
      status: searchColumn === 'status' ? e : status,
    };

    console.log(searchObject);

    getInventories(0, 5, searchObject);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sx={{ width: headCell.width }}
          >
            {headCell.label !== 'Actions' && (
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setSearchColumn(headCell.property);
                }}
              >
                <FilterListIcon />
              </IconButton>
            )}
            <Box sx={{ height: 30 }}>{headCell.label}</Box>
          </TableCell>
        ))}
      </TableRow>

      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '14px',
            padding: '14px',
            display: 'flex',
          }}
        >
          <TextField
            autoFocus
            label={`Search ${searchColumn}`}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {
                height: '10px',
              },
            }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Box sx={{ marginLeft: '10px' }}>
            <IconButton onClick={() => setAnchorEl(null)} size="small">
              <Box
                sx={{
                  paddingInline: '10px',
                  paddingBlock: '6px',
                  background: 'red',
                  borderRadius: '8px',
                  color: 'white',
                }}
              >
                x
              </Box>
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </TableHead>
  );
};

InventoryHead.propTypes = {
  // handleSearch: PropTypes.func,
};
