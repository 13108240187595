import { useEffect, useState } from 'react';
import { useEditRequest, useGetRequest, useRequestActions } from 'src/components/hooks';

import {
  Drawer,
  Box,
  Select,
  MenuItem,
  Button,
  IconButton,
  TextField,
  Typography,
  Label,
  Tab,
  InputLabel,
  FormControl,
  OutlinedInput,
  ConfirmDialog,
} from 'src/components/ui';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, FormikProvider, Form, ErrorMessage } from 'formik';
// import { SettingsIcon } from 'src/components/assets/icons';
import { fDate } from 'src/components/utils/format-time';
import { useGetNexudusUser } from 'src/components/hooks/nexudus/useGetNexudusUser';
import { Link } from 'react-router-dom';

export const RequestDrawer = () => {
  const { isDrawerOpen, setIsDrawerOpen, id, editRequest } = useEditRequest();

  const { getRequest, data: requestData } = useGetRequest();
  const { getNexudusUserData, userData } = useGetNexudusUser();
  const [accountInfo, setAccountInfo] = useState(null);

  const [actionValue, setActionValue] = useState('');
  const { setRequestStatus } = useRequestActions();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [value, setValue] = useState('1');
  const [edit, setEdit] = useState(true);

  const formik = useFormik({
    initialValues: {
      item_amount: 1,
      admin_note: '',
      customer_note: '',
    },

    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (e) => {
      editRequest(e, e.id);
      setRequestStatus(e.id, actionValue);
      handleOpenedDrawer();
    },
  });

  useEffect(() => {
    if (!id) return;
    getRequest(id);
  }, [id]);

  useEffect(() => {
    if (!requestData) return;
    formik.setValues(requestData);
    // getNexudusUserData();
    getNexudusUserData(requestData.account?.nexudus_id);
  }, [requestData]);

  useEffect(() => {
    if (!userData) return;
    setAccountInfo(userData);
  }, [userData]);

  const handleOpenedDrawer = () => {
    setIsDrawerOpen(false);
    formik.resetForm();
    setActionValue('');
    setEdit(true);
  };

  if (!isDrawerOpen) {
    return null;
  }

  const handleAddAdminNote = () => {
    setIsModalOpen(false);
  };

  console.log(userData);
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleOpenedDrawer}
      PaperProps={{
        sx: {
          width: '30%',
          '@media (max-width: 600px)': {
            width: '70%',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {/* <Link
            href={formik.values.account?.profile_url}
            target="_blank"
            rel="noopener noreferrer"
          > */}
          <FormControl fullWidth sx={{ marginTop: '24px', width: '150px' }}>
            <Label color="info" sx={{ borderRadius: '12px', padding: '1.6rem' }}>
              {userData?.FullName || 'N/A'}
            </Label>
          </FormControl>
          {/* </Link> */}

          <FormControl sx={{ marginTop: '1rem', width: '150px' }}>
            <InputLabel id="name-label">Actions</InputLabel>
            <Select
              value={actionValue}
              onChange={(e) => {
                setActionValue(e.target.value);
              }}
              size="medium"
              input={<OutlinedInput label="Actions" />}
            >
              <MenuItem value="approve">Approve</MenuItem>
              <MenuItem value="decline">Decline</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <IconButton onClick={handleOpenedDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>

      <FormikProvider value={formik}>
        <Form
          style={{
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Details" value="1" />
                  <Tab label="Notes" value="2" />
                  <Tab label="Account Info" value="3" />
                </TabList>
              </Box>
              {/* Details */}
              <TabPanel value="1">
                <Box sx={{ display: 'flex', gap: '24px' }}>
                  <Box sx={{ width: '50%' }}>
                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        name="item_amount"
                        label="Amount"
                        value={formik.values?.item_amount || 'N/A'}
                        onChange={formik.handleChange}
                        type="number"
                      />
                    </FormControl>
                    {/* 
                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        label="Personal Number"
                        disabled
                        variant="filled"
                        value={formik.values.account?.nexudus_user_id || 'N/A'}
                      />
                    </FormControl> */}

                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        label="Account Type"
                        disabled
                        variant="filled"
                        value={
                          formik.values.account?.account_type === 1
                            ? 'Personal'
                            : 'Company' || 'N/A'
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        label="Additional Service"
                        disabled
                        variant="filled"
                        value={formik.values?.additional_services === 0 || 'None'}
                      />
                    </FormControl>
                  </Box>
                  {/* item */}
                  <Box sx={{ width: '50%' }}>
                    <FormControl
                      fullWidth
                      sx={{
                        marginTop: '24px',
                        '.MuiInputBase-input': {
                          textTransform: 'capitalize',
                        },
                      }}
                    >
                      <TextField
                        label="Requested Item Type"
                        disabled
                        variant="filled"
                        value={formik.values.inventory?.type || 'N/A'}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        label="Requested Item Category"
                        disabled
                        variant="filled"
                        value={formik.values.inventory?.category?.title || 'N/A'}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        label="Requested Item"
                        disabled
                        variant="filled"
                        value={formik.values.inventory?.name || 'N/A'}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        name="requestedDate"
                        label="Requested Date"
                        disabled
                        variant="filled"
                        value={fDate(formik.values?.created_at) || 'N/A'}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ marginTop: '24px' }}>
                      <TextField
                        name="activationDate"
                        label="Activation Date"
                        disabled
                        variant="filled"
                        value={fDate(formik.values?.activation_date) || 'N/A'}
                      />
                    </FormControl>
                  </Box>
                </Box>

                {/* ----Details Save--- */}
              </TabPanel>
              {/* Details */}
              {/* Notes */}
              <TabPanel value="2">
                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="admin_note"
                    label="Admin Note"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    rows={4}
                    value={formik.values.admin_note}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </TabPanel>

              {/* accoutn info */}
              <TabPanel value="3">
                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    label="User ID"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={formik.values.account?.nexudus_id || 'N/A'}
                    onChange={formik.handleChange}
                  />
                </FormControl>

                {/* <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    label="Company Name"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={userData?.FullName}
                    onChange={formik.handleChange}
                  />
                </FormControl> */}

                {/* <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="company_id"
                    label="Company ID"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={formik.values.company_id}
                    onChange={formik.handleChange}
                  />
                </FormControl> */}

                {/* <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="registration_adress"
                    label="Registration Adress"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={formik.values.registration_adress}
                    onChange={formik.handleChange}
                  />
                </FormControl> */}

                {/* <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="industry"
                    label="Industry"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={formik.values.industry}
                    onChange={formik.handleChange}
                  />
                </FormControl> */}

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="linkedin"
                    label="Linkedin"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={userData?.Linkedin || 'N/A'}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="instagram"
                    label="Instagram"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={userData?.Instagram || 'N/A'}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="facebook"
                    label="Facebook"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={userData?.Facebook || 'N/A'}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="github"
                    label="Github"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={userData?.Github || 'N/A'}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={userData?.MobilePhone || 'N/A'}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="email"
                    label="Email adress"
                    multiline
                    disabled={Boolean(true)}
                    variant={edit ? 'filled' : 'outlined'}
                    value={accountInfo?.Email}
                  />
                </FormControl>
              </TabPanel>
              {/* Notes */}
              <Box
                sx={{
                  margin: '0 auto',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '24px',
                  width: '90%',
                }}
              >
                {value === '2' && (
                  <Button
                    sx={{
                      position: 'relative',
                      width: '100px',
                      height: '50px',
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: '#F8A504',
                    }}
                    variant="contained"
                    // disabled={!isValid}
                    onClick={() => setIsModalOpen(true)}
                  >
                    Add
                  </Button>
                )}

                <Box>
                  <Button
                    sx={{
                      position: 'relative',
                      width: '100px',
                      height: '50px',
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: '#3B25FF',
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    // disabled={!isValid}
                  >
                    Save
                  </Button>

                  <Label
                    sx={{
                      position: 'relative',
                      minWidth: '100px',
                      height: '50px',
                      cursor: 'pointer',
                      marginLeft: '24px',
                    }}
                    onClick={handleOpenedDrawer}
                  >
                    Cancel
                  </Label>
                </Box>

                <ConfirmDialog
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  apply={() => handleAddAdminNote()}
                  content={
                    <Box>
                      <Typography>Add admin note</Typography>
                      <FormControl fullWidth sx={{ marginTop: '24px' }}>
                        <TextField
                          name="admin_note"
                          label="Admin Note"
                          multiline
                          // disabled={edit}
                          variant={edit ? 'filled' : 'outlined'}
                          rows={4}
                          value={formik.values.admin_note}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Box>
                  }
                />
              </Box>
            </TabContext>
          </Box>
        </Form>
      </FormikProvider>
    </Drawer>
  );
};
