import { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterListIcon } from 'src/components/assets/icons';
import {
  Popover,
  TableRow,
  TableCell,
  TableHead,
  Button,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  SearchIcon,
  Box,
  TextField,
  IconButton,
  CustomDateRangePicker,
} from 'src/components/ui';
import { useGetRequests } from 'src/components/hooks';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const columns = [
  { id: 1, label: 'Nexudus UserId', property: 'nexudus_user_id' },
  { id: 2, label: 'Coworker', property: 'accountType' },
  { id: 4, label: 'Amount', property: 'itemAmount' },
  { id: 3, label: 'Additional Service' },
  { id: 5, label: 'Requested Date', property: 'requestedDate' },
  { id: 6, label: 'Status', property: 'status' },
  { id: 8, label: 'Last Seen', property: 'lastSeen' },
  { id: 9, label: 'Requested Item' },
  { id: 10, label: 'Purchased Item' },
  { id: 11, label: 'Purchase Date', property: 'purchaseDate' },
  { id: 12, label: 'Actions' },
];

export const RequestsHead = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchColumn, setSearchColumn] = useState('');

  // objectState
  const [nexudus_user_id, setNexudus_user_id] = useState('');
  const [accountType, setAccountType] = useState('');
  const [itemAmount, setItemAmount] = useState('');
  const [status, setStatus] = useState('');
  const [requestedData, setRequestedData] = useState('');
  const [lastSeen, setLastSeen] = useState('');
  const [purchaseData, setPurchaseData] = useState('');

  const { getRequests } = useGetRequests();

  const handleSearch = (e) => {
    if (searchColumn === 'nexudus_user_id') {
      setNexudus_user_id(e);
    } else if (searchColumn === 'accountType') {
      setAccountType(e);
    } else if (searchColumn === 'itemAmount') {
      setItemAmount(e);
    } else if (searchColumn === 'status') {
      setStatus(e);
    } else if (searchColumn === 'requestedDate' || searchColumn === 'lastSeen') {
      setRequestedData(e);
    }
    // else if (searchColumn === 'purchaseDate') {
    //   setPurchaseData(e);
    // }
    // } else if (searchColumn === 'lastSeen') {
    //   setLastSeen(e);
    // }

    const searchObject = {
      nexudus_user_id: searchColumn === 'nexudus_user_id' ? e : nexudus_user_id,
      accountType: searchColumn === 'accountType' ? e : accountType,
      itemAmount: searchColumn === 'itemAmount' ? e : itemAmount,
      status: searchColumn === 'status' ? e : status,
      requestedDate: searchColumn === 'requestedDate' ? e : requestedData,
      // lastSeen: searchColumn === 'lastSeen' ? e : lastSeen,
      // purchaseDate: searchColumn === 'purchaseData' ? e : purchaseData,
    };

    getRequests(0, 5, searchObject);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell key={headCell.id} sx={{ width: '224px' }}>
            {headCell.label !== 'Actions' && (
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setSearchColumn(headCell.property);
                }}
              >
                <FilterListIcon />
              </IconButton>
            )}
            <Box sx={{ height: 50 }}>{headCell.label}</Box>
          </TableCell>
        ))}

        <Popover
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
          }}
          transformOrigin={{
            vertical: 'top',
          }}
        >
          {(searchColumn === 'nexudus_user_id' || searchColumn === 'itemAmount') && (
            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '14px',
                padding: '10px',
                display: 'flex',
              }}
            >
              <TextField
                autoFocus
                label={`Search ${searchColumn}`}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    height: '10px',
                  },
                }}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Box sx={{ marginLeft: '10px' }}>
                <IconButton onClick={() => setAnchorEl(null)} size="small">
                  <Box
                    sx={{
                      paddingInline: '10px',
                      paddingBlock: '6px',
                      background: 'red',
                      borderRadius: '8px',
                      color: 'white',
                    }}
                  >
                    x
                  </Box>
                </IconButton>
              </Box>
            </Box>
          )}

          {searchColumn === 'accountType' && (
            <Box sx={{ padding: '14px' }}>
              <InputLabel id="Name-label">AccountType</InputLabel>
              <Select
                sx={{ width: '140px' }}
                value={accountType}
                onChange={(e) => handleSearch(e.target.value)}
              >
                <MenuItem key="accountType1" value="1">
                  Personal
                </MenuItem>
                <MenuItem key="accountType2" value="2">
                  Company
                </MenuItem>
              </Select>
            </Box>
          )}

          {searchColumn === 'status' && (
            <Box sx={{ padding: '14px' }}>
              <InputLabel id="Name-label">Status</InputLabel>
              <Select
                sx={{ width: '140px' }}
                value={status}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              >
                <MenuItem key="New" value="NEW">
                  New
                </MenuItem>
                <MenuItem key="Declined" value="DECLINED">
                  Declined
                </MenuItem>
                <MenuItem key="Approved" value="APPROVED">
                  Approved
                </MenuItem>
                <MenuItem key="Purchased" value="PURCHASED">
                  Purchased
                </MenuItem>
              </Select>
            </Box>
          )}

          {searchColumn === 'requestedDate' && (
            <Box sx={{ padding: '14px' }}>
              <DatePicker
                label={`Select ${searchColumn}`}
                value={requestedData}
                onChange={(e) => {
                  handleSearch(e);
                }}
              />
            </Box>
          )}

          {searchColumn === 'lastSeen' && (
            <Box sx={{ padding: '14px' }}>
              <DatePicker
                label={`Select ${searchColumn}`}
                value={lastSeen}
                onChange={(e) => {
                  handleSearch(e);
                }}
              />
            </Box>
          )}

          {searchColumn === 'purchaseDate' && (
            <Box sx={{ padding: '14px' }}>
              <DatePicker
                label={`Select ${searchColumn}`}
                value={purchaseData}
                onChange={(e) => {
                  handleSearch(e);
                }}
              />
            </Box>
          )}
        </Popover>
      </TableRow>
    </TableHead>
  );
};

RequestsHead.propTypes = {
  // handleSearch: PropTypes.func,
};
