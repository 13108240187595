import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  useGetNexudusData,
  useEditInventory,
  useGetNexudusResourceData,
} from 'src/components/hooks';

import {
  Box,
  Select,
  MenuItem,
  Button,
  IconButton,
  Label,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  Switch,
} from 'src/components/ui';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormikProvider, Form, ErrorMessage } from 'formik';
import { filteredTautology } from './utils/utils';

const resourcesCategory = [
  {
    BusinessId: 1415046969,
    Name: 'Conference Room',
    Id: 1415154254,
    UpdatedOn: '2024-06-25T14:00:50Z',
    CreatedOn: '2024-06-25T14:00:50Z',
    UniqueId: '84768d36-363b-4ccc-9d4e-2ae8b9fc64f1',
    UpdatedBy: 'papunidze.tatia@gmail.com',
    IsNew: false,
    SystemId: null,
    ToStringText: 'Conference Room',
    LocalizationDetails: null,
    CustomFields: null,
  },
  {
    BusinessId: 1415046969,
    Name: 'D Pass',
    Id: 1415105361,
    UpdatedOn: '2023-11-12T19:49:53Z',
    CreatedOn: '2023-11-12T19:49:53Z',
    UniqueId: '1a504097-d071-4e01-83b7-856ccf715a46',
    UpdatedBy: 'anzortamta@gmail.com',
    IsNew: false,
    SystemId: null,
    ToStringText: 'D Pass',
    LocalizationDetails: null,
    CustomFields: null,
  },
  {
    BusinessId: 1415046969,
    Name: 'Locker',
    Id: 1415154046,
    UpdatedOn: '2024-06-25T14:00:56Z',
    CreatedOn: '2024-06-25T14:00:56Z',
    UniqueId: '6a6865b2-e30e-4c29-a3e6-f96b032813eb',
    UpdatedBy: 'papunidze.tatia@gmail.com',
    IsNew: false,
    SystemId: null,
    ToStringText: 'Locker',
    LocalizationDetails: null,
    CustomFields: null,
  },
  {
    BusinessId: 1415046969,
    Name: 'Meeting Room',
    Id: 1415165035,
    UpdatedOn: '2024-08-06T10:22:48Z',
    CreatedOn: '2024-08-06T10:22:48Z',
    UniqueId: '95300631-a0b2-470f-9383-8d38cf907279',
    UpdatedBy: 'gp.paresishvili@gmail.com',
    IsNew: false,
    SystemId: null,
    ToStringText: 'Meeting Room',
    LocalizationDetails: null,
    CustomFields: null,
  },
];
export const StockTab = ({ id, formik, setResourceTag }) => {
  const { setIsDrawerOpen, actionType } = useEditInventory();

  // plans / products
  const { categoryData, nameData } = useGetNexudusData();
  // resources
  const { resourceNameData } = useGetNexudusResourceData();

  const filteredCategoryData = filteredTautology(
    categoryData?.Records,
    formik.values.type
  );

  const handleOpenedDrawer = () => {
    setIsDrawerOpen(false);
    formik.resetForm();
  };

  return (
    <FormikProvider value={formik}>
      <Form
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* type */}
        <FormControl fullWidth sx={{ marginTop: '24px' }}>
          <InputLabel id="Type-label">Type</InputLabel>
          <Select
            name="type"
            label="Type"
            labelId="Type-label"
            value={formik.values.type}
            onChange={formik.handleChange}
            disabled={actionType === 'edit' ? Boolean(true) : false}
            error={formik.touched.type && Boolean(formik.errors.type)}
          >
            <MenuItem value="membership">Membership</MenuItem>
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="resource">Resource</MenuItem>
          </Select>
          <ErrorMessage name="type" component="div" style={{ color: '#fb5630' }} />
        </FormControl>

        {/* category */}
        <FormControl fullWidth sx={{ marginTop: '24px' }}>
          <InputLabel id="Category-label">Category</InputLabel>
          <Select
            name="category"
            labelId="Category-label"
            label="Category"
            value={formik.values?.category}
            onChange={(e) => {
              formik.setFieldValue('category', e.target.value);
            }}
            error={formik.touched.category && Boolean(formik.errors.category)}
          >
            {formik.values.type === 'membership' &&
              filteredCategoryData?.map((el) => (
                <MenuItem key={el.Id} value={el.GroupName}>
                  {el.GroupName}
                </MenuItem>
              ))}

            {formik.values.type === 'product' &&
              filteredCategoryData?.map((el) => (
                <MenuItem key={el.Id} value={el.Tags}>
                  {el.Tags}
                </MenuItem>
              ))}
            {formik.values.type === 'resource' &&
              resourcesCategory?.map((el) => (
                <MenuItem key={el.Id} value={el.Name}>
                  {el.Name}
                </MenuItem>
              ))}
          </Select>
          <ErrorMessage name="category" component="div" style={{ color: '#fb5630' }} />
        </FormControl>

        {/* name */}
        <FormControl fullWidth sx={{ marginTop: '24px' }}>
          <InputLabel id="Name-label">Name</InputLabel>
          <Select
            name="name"
            labelId="Name-label"
            label="Name"
            value={formik.values.name}
            onChange={(e) => {
              const searchedNames =
                formik.values.type === 'resource' ? resourceNameData : nameData;

              const selectedName = e.target.value;
              const selectedItem = searchedNames.Records.find(
                (el) => el.Name === selectedName
              );

              formik.setFieldValue('name', selectedName);
              formik.setFieldValue('nexudus_id', Number(selectedItem?.Id) || null);
            }}
            error={formik.touched.category && Boolean(formik.errors.category)}
          >
            {formik.values.type === 'membership' &&
              nameData.Records?.map((el) => (
                <MenuItem key={el.Id} value={el.Name}>
                  {el.Name}
                </MenuItem>
              ))}

            {formik.values.type === 'product' &&
              nameData.Records?.map((el) => (
                <MenuItem key={el.Id} value={el.Name}>
                  {el.Name}
                </MenuItem>
              ))}

            {formik.values.type === 'resource' &&
              resourceNameData.Records?.map((el) => (
                <MenuItem key={el.Id} value={el.Name}>
                  {el.Name}
                </MenuItem>
              ))}
          </Select>
          <ErrorMessage name="category" component="div" style={{ color: '#fb5630' }} />
        </FormControl>

        {/* daily */}
        {formik.values?.type !== 'resource' && (
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <FormLabel>
              Daily
              <Switch
                name="is_daily"
                checked={formik.values.is_daily}
                onChange={formik.handleChange}
              />
            </FormLabel>
          </FormControl>
        )}

        {formik.values.type !== 'resource' && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControl sx={{ marginTop: '24px', width: '48%' }}>
                <DatePicker
                  name="start_date"
                  label="Start date"
                  value={
                    formik.values.start_date ? new Date(formik.values.start_date) : null
                  }
                  onChange={(date) => formik.setFieldValue('start_date', date)}
                />
              </FormControl>

              <FormControl sx={{ marginTop: '24px', width: '48%' }}>
                <DatePicker
                  name="end_date"
                  label="End date"
                  value={formik.values.end_date ? new Date(formik.values.end_date) : null}
                  onChange={(date) => formik.setFieldValue('end_date', date)}
                />
              </FormControl>
            </Box>

            <FormControl fullWidth sx={{ marginTop: '24px' }}>
              <TextField
                name="stock"
                label="Quantity"
                value={formik.values.stock}
                onChange={formik.handleChange}
                type="number"
              />
              <ErrorMessage name="stock" component="div" style={{ color: '#fb5630' }} />
            </FormControl>
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            marginTop: '24px',
          }}
        >
          <Button
            sx={{
              position: 'relative',
              width: '100px',
              height: '50px',
              cursor: 'pointer',
              color: 'white',
              backgroundColor: '#3B25FF',
            }}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
          <Label
            sx={{
              position: 'relative',
              minWidth: '100px',
              height: '50px',
              cursor: 'pointer',
              marginLeft: '24px',
            }}
            onClick={handleOpenedDrawer}
          >
            Cancel
          </Label>
        </Box>
      </Form>
    </FormikProvider>
  );
};

StockTab.propTypes = {
  id: PropTypes.string,
  formik: PropTypes.object,
  setResourceTag: PropTypes.string,
};
